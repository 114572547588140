"use client";

import { ReactNode, useEffect } from "react";
import Analytics from "@/components/common/Analytics";
import Styles from "@/components/common/Styles";
import { Auth0Provider } from "@auth0/auth0-react";
import { AuthContextProvider } from "@/contexts/common/AuthContextProvider";
import { PHProvider, PostHogPageview } from "./providers";
import { AlertContextProvider } from "@/hooks/useAlert";
import { NextUIProvider } from "@nextui-org/react";
import { NavContainerProvider } from "@/contexts/common/NavContainerProvider";
import Zendesk from "@/components/ZenDesk";
import ReleaseNoteInitialization from "./releaseNotesInitialization";
import { ProfileContextProvider } from "@/hooks/useProfile";
import UserManagementModal from "@/components/common/UserManagementModal";
import React from "react";
import "@/i18n/config";
import { useTranslation } from "react-i18next";
import { BRANDS } from "@/enums/brands";
import { GetBrand } from "@/utils/common/theme";
import { RestrictionsContextProvider } from "@/hooks/useRestrictions";

export default function RootLayout({ children }: { children: ReactNode }) {
  const { i18n } = useTranslation();

  const [show, setShow] = React.useState(false);
  const [language, setLanguage] = React.useState("en");
  const [brand, setBrand] = React.useState<BRANDS>(BRANDS.GAIL);
  const [mode, setMode] = React.useState<"light" | "dark">("light");

  const handleLanguageChange = (language: string) => {
    setLanguage(language);
    i18n.changeLanguage(language);
  };

  useEffect(() => {
    handleLanguageChange(navigator.language);
    setBrand(GetBrand(window.location.host));

    const cachedMode = window.localStorage.getItem("theme");

    if (cachedMode === "dark" || cachedMode === "light") {
      setMode(cachedMode);
    } /* else {
      setMode(
        window.matchMedia &&
          window.matchMedia("(prefers-color-scheme: dark)").matches
          ? "dark"
          : "light"
      );
    }*/
  }, []);

  useEffect(() => {
    const targetNode = document.documentElement;

    const observerCallback = (mutationsList: any) => {
      for (const mutation of mutationsList) {
        if (
          mutation.type === "attributes" &&
          mutation.attributeName === "data-theme"
        ) {
          const currentTheme = targetNode.getAttribute("data-theme");
          if (currentTheme !== mode) {
            targetNode.setAttribute("data-theme", mode);
          }
        }
      }
    };

    const observer = new MutationObserver(observerCallback);
    observer.observe(targetNode, { attributes: true });

    return () => {
      observer.disconnect();
    };
  }, [mode]);

  return (
    <html
      lang={language}
      data-brand={brand}
      data-theme={mode}
      style={{ backgroundColor: "#333354" }}
    >
      <head>
        <script
          src="https://kit.fontawesome.com/26f92a71e5.js"
          crossOrigin="anonymous"
          async
        ></script>
        <link rel="icon" href="/favicon.ico" />
      </head>
      <PHProvider>
        <body>
          <ReleaseNoteInitialization />
          <PostHogPageview />
          <Analytics />
          <Styles />
          <Auth0Provider
            domain={process.env.NEXT_PUBLIC_AUTH0_DOMAIN!}
            clientId={process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID!}
            useRefreshTokens
            cacheLocation="localstorage"
            cookieDomain={process.env.NEXT_PUBLIC_AUTH0_COOKIE_DOMAIN!}
          >
            <AuthContextProvider>
              <NextUIProvider>
                <ProfileContextProvider setLanguage={handleLanguageChange}>
                  <NavContainerProvider setShowUserManagement={setShow}>
                    <AlertContextProvider isAdmin={false}>
                      <RestrictionsContextProvider>
                        <Zendesk />
                        <UserManagementModal show={show} setShow={setShow} />
                        {children}
                      </RestrictionsContextProvider>
                    </AlertContextProvider>
                  </NavContainerProvider>
                </ProfileContextProvider>
              </NextUIProvider>
            </AuthContextProvider>
          </Auth0Provider>
        </body>
      </PHProvider>
    </html>
  );
}
